<template>
  <div>
    <p class="text-sm-2 text-grey-dark-2 mb-2">Showing {{ totalEntries }} Entreis</p>
    <div>
      <div class="flex flex-row w-full bg-grey-light-3 py-1 pl-2 rounded" :class="history.length < 10 ? '' : 'pr-2'">
        <button class="flex flex-row items-center focus:outline-none" data-testid="header-2" style="width: 140px">
          <p class="text-sm-2 text-grey-light mr-0.4">ACTIVITY DATE</p>
        </button>
        <button class="flex flex-row items-center focus:outline-none" data-testid="header-3" style="width: 120px">
          <p class="text-sm-2 text-grey-light mr-0.4">ACTIVITY</p>
        </button>
        <button class="flex flex-row items-center focus:outline-none ml-4" data-testid="header-4" style="width: 130px">
          <p class="text-sm-2 text-grey-light mr-0.4">LEAD GENERATION</p>
        </button>
        <button class="flex flex-row items-center focus:outline-none ml-4" data-testid="header-4" style="width: 120px">
          <p class="text-sm-2 text-grey-light mr-0.4">START DATE</p>
        </button>
        <button class="flex flex-row items-center focus:outline-none ml-4" data-testid="header-4" style="width: 120px">
          <p class="text-sm-2 text-grey-light mr-0.4">END DATE</p>
        </button>
        <button class="flex flex-row items-center focus:outline-none ml-4" data-testid="header-4" style="width: 250px">
          <p class="text-sm-2 text-grey-light mr-0.4">RESPONSIBLE NAME</p>
        </button>
        <button class="flex flex-row items-center focus:outline-none ml-4" data-testid="header-4">
          <p class="text-sm-2 text-grey-light mr-0.4"></p>
        </button>
      </div>
      <div>
        <VirtualList
          style="max-height: 360px; overflow-y: auto"
          :data-key="'startDate'"
          :data-sources="history"
          :data-component="itemComponent"
          @tobottom="triggerFetch"
        />
      </div>
    </div>
  </div>
</template>
<script>
import HistoryTableItem from './HistoryTableItem.vue';
import VirtualList from 'vue-virtual-scroll-list';
export default {
  name: 'SubscriptionHistoryTable',
  data: () => ({
    itemComponent: HistoryTableItem,
  }),
  components: {
    HistoryTableItem,
    VirtualList,
  },
  props: {
    totalEntries: {
      type: Number,
      default: 0
    },
    history: {
      type: Array,
      default: () => [],
    },
    triggerFetch: {
      type: Function,
      default: () => ({}),
    },
  },
};
</script>
