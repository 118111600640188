<template>
  <div>
    <div class="flex flex-row">
      <div>
        <h1 class="text-xl font-medium font-headers mb-3 font-bold text-grey-dark-2">
          <span class="text-grey-medium-2 font-normal">
            Companies / Subscription and Invites /
          </span>
          Subscription History
        </h1>
      </div>
    </div>
    <div>
      <SubscriptionHistory />
    </div>
  </div>
</template>
<script>
import SubscriptionHistory from "../components/SubscriptionHistory/index.vue";
export default {
  name: 'SubscriptionsHistory',
  components: {
    SubscriptionHistory
  }
};
</script>
