<template>
  <div :data-testid="`historytable-listitem-${index}`">
    <div class="flex flex-row w-full py-1 pl-2 items-center">
      <div class="flex pr-3" style="width: 140px">
        <p class="font-normal text-md text-grey-dark-2">
          {{ formatDate(source.modificationDate) }}
        </p>
      </div>
      <div class="flex" style="width: 120px">
        <p class="text-md" :class="activityClass">
          {{ activity }}
        </p>
      </div>
      <div class="flex items-center  justify-center" style="width: 170px">
        <img :src="checkIcon" v-show="source.isUseForLeadGeneration" />
      </div>
      <div class="flex items-center pl-4" style="width: 140px">
        <p class="text-md text-grey-dark-1 font-semibold text-center">
          {{ formatDate(source.startDate) }}
        </p>
      </div>
      <div class="flex pl-5" style="width: 140px">
        <p class="text-md text-grey-dark-1 font-semibold">
          {{ formatDate(source.endDate) }}
        </p>
      </div>
      <div class="flex pl-6" style="width: 250px">
        <p class="text-md">
          {{ source.responsableName }}
        </p>
      </div>
      <div class="flex flex-row flex-1 pr-2 items-center focus:outline-none ml-2 justify-end" style="height: 38px">
        <router-link to="subscription-details">
          <Button
            v-if="index === 0"
            text="Manage"
            type="secondary"
            size="medium"
            data-testid="manage-button"
            :iconRight="arrowIcon"
          />
        </router-link>
      </div>
    </div>
    <div class="border border-grey-light-1 border-top-none" />
  </div>
</template>
<script>
import moment from 'moment';
import checkIcon from '../../../../../assets/img/icons/check-blue.svg';
import arrowIcon from '../../../../../assets/img/icons/arrow-right.svg';
export default {
  name: 'ListItem',
  props: {
    index: { type: Number },
    source: { type: Object, default: () => ({}) },
    manage: { type: Function, default: () => null },
  },
  data: () => ({
    checkIcon,
    arrowIcon
  }),
  methods: {
    formatDate(date) {
      return moment(date).format('MMM D, YYYY');
    },
  },
  computed: {
    activity() {
      if (this.source.subscriptionStatus === 4) {
        return 'Expired';
      }
      switch (this.source.subscriptionAction) {
        case 0:
          return 'No Status';
        case 1:
          return 'Activated';
        case 2:
          return 'Deactivated';
        case 3:
          return 'Suspended';
        case 4:
          return 'Updated';
        case 5:
          return 'Updated';
        default:
          return 'No valid status returned'
      }
    },
    activityClass() {
      if (this.source.subscriptionStatus === 4) {
        return 'text-red';
      }
      switch (this.source.subscriptionAction) {
        case 1:
          return 'text-green-2';
        case 2:
          return 'text-red';
        case 3:
          return 'text-grey-medium-2';
        case 4:
          return 'text-blue-dark-1';
        case 5:
          return 'text-blue-dark-1';
        default:
          return ''
      }
    },
  }
};
</script>
