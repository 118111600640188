<template>
  <div class="bg-white shadow px-2 pt-2 pb-2 rounded">
    <p class="font-barlow text-mdl font-bold text-grey-light mb-2">History of subscriptions</p>
    <SubscriptionHistoryTable :history="history" :triggerFetch="triggerFetch" :totalEntries="totalEntries"  />
  </div>
</template>
<script>
import { getSubscriptionHistory } from '../../../../services/api/subscriptions';
import SubscriptionHistoryTable from './components/SubscriptionHistoryTable.vue';

export default {
  name: 'SubscriptionHistory',
  components: {
    SubscriptionHistoryTable
  },
  data: () => ({
    history: [],
    page: 1,
    totalEntries: 0
  }),
  methods: {
    async getHistory(paginated = false) {
      const orgId = parseInt(this.$route.params.companyId, 10);
      const history = await getSubscriptionHistory(this.page, 20, orgId);
      this.totalEntries = history.data.totalCount;
      if (paginated) {
        this.history.push(...history.data.data);
      } else {
        this.history = history.data.data;
      }
    },
    triggerFetch() {
      if (this.page < Math.ceil(this.totalEntries / 20)) {
        this.page++;
        this.getHistory(true);
      }
    },
  },
  mounted() {
    this.getHistory();
  }
}
</script>
